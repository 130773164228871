import { CONNECTOR_TYPES } from '@/vars/general'
import { maskPhonePlain } from '@root/src/utils'
import moment from 'moment'
import { WhatsappLinkSettings } from '../../../types/GCB2'
import { HrefInfo } from '../../../types/main'

/**
 *
 * @param {Object} item
 * @returns  возвращает ссылку на клиента в yClients
 */
export function getClientRef(item: any) {
    const href_info = JSON.parse(item.href_info)
    if (href_info.connectorType === CONNECTOR_TYPES.yClients) {
        return `https://yclients.com/clients/${href_info.company_id}/base?fields%5B0%5D=name&fields%5B1%5D=phone&fields%5B2%5D=email&fields%5B3%5D=sold_amount&fields%5B4%5D=visits_count&fields%5B5%5D=discount&fields%5B6%5D=last_visit_date&fields%5B7%5D=first_visit_date&order_by=id&order_by_direction=desc&page=1&page_size=25&segment=&operation=AND&filters%5B0%5D%5Boperation%5D=OR&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B1%5D%5Btype%5D=quick_search&filters%5B1%5D%5Bstate%5D%5Bvalue%5D=${href_info.phone}`
    }
    if (href_info.connectorType === 'waLink') {
        return `https://wa.me/${maskPhonePlain(href_info.phone)}?text=${encodeURIComponent(href_info.text)}`
    }
}
export function getYcClientRef(item: any) {
    const href_info = JSON.parse(item.href_info)
    const HrefInfoYc = href_info.find((el: any) => el.connectorType === CONNECTOR_TYPES.yClients)
    return `https://yclients.com/clients/${HrefInfoYc.company_id}/base?fields%5B0%5D=name&fields%5B1%5D=phone&fields%5B2%5D=email&fields%5B3%5D=sold_amount&fields%5B4%5D=visits_count&fields%5B5%5D=discount&fields%5B6%5D=last_visit_date&fields%5B7%5D=first_visit_date&order_by=id&order_by_direction=desc&page=1&page_size=25&segment=&operation=AND&filters%5B0%5D%5Boperation%5D=OR&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B1%5D%5Btype%5D=quick_search&filters%5B1%5D%5Bstate%5D%5Bvalue%5D=${HrefInfoYc.phone}`
}
export function getClientRefFromParsed(
    hrefInfo: HrefInfo,
    whatsappLinkSettings: WhatsappLinkSettings,
    textMessage?: string
) {
    let link = 'about:blank'
    if (hrefInfo.connectorType === CONNECTOR_TYPES.altegio) {
        link = `https://app.alteg.io/clients/${hrefInfo.company_id}/base?fields%5B0%5D=name&fields%5B1%5D=phone&fields%5B2%5D=email&fields%5B3%5D=sold_amount&fields%5B4%5D=visits_count&fields%5B5%5D=discount&fields%5B6%5D=last_visit_date&fields%5B7%5D=first_visit_date&order_by=id&order_by_direction=desc&page=1&page_size=25&segment=&operation=AND&filters%5B0%5D%5Boperation%5D=OR&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B1%5D%5Btype%5D=quick_search&filters%5B1%5D%5Bstate%5D%5Bvalue%5D=${hrefInfo.phone}`
    } else if (hrefInfo.connectorType === CONNECTOR_TYPES.yClients) {
        link = `https://yclients.com/clients/${hrefInfo.company_id}/base?fields%5B0%5D=name&fields%5B1%5D=phone&fields%5B2%5D=email&fields%5B3%5D=sold_amount&fields%5B4%5D=visits_count&fields%5B5%5D=discount&fields%5B6%5D=last_visit_date&fields%5B7%5D=first_visit_date&order_by=id&order_by_direction=desc&page=1&page_size=25&segment=&operation=AND&filters%5B0%5D%5Boperation%5D=OR&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B0%5D%5Bfilters%5D%5B0%5D%5Bfilters%5D%5B0%5D%5Boperation%5D=AND&filters%5B1%5D%5Btype%5D=quick_search&filters%5B1%5D%5Bstate%5D%5Bvalue%5D=${hrefInfo.phone}`
    } else if (hrefInfo.connectorType === CONNECTOR_TYPES.dikidi) {
        link = `https://dikidi.ru/ru/owner/clients/?company=${hrefInfo.companyId}&limit=20&offset=0&filter=username&order=asc&query=${hrefInfo.phone}`
    } else if (hrefInfo.connectorType === CONNECTOR_TYPES.sonline) {
        link = `https://new.sonline.su/nusers/main/${hrefInfo.client_id}`
    } else if (
        hrefInfo.connectorType === CONNECTOR_TYPES.profSalon ||
        hrefInfo.connectorType === CONNECTOR_TYPES.profSalonBat
    ) {
        link = `https://profsalon.org/CRM/${hrefInfo.profSalonLogin}/clients/card/history/#!/uid=${hrefInfo.clientId}`
    } else if (hrefInfo.connectorType === 'waLink') {
        if (whatsappLinkSettings) {
            let template = whatsappLinkSettings.template ?? `https://wa.me/{{phone}}?text={{text}}`
            if (whatsappLinkSettings.textType === 'blank') {
                link = template.replace('{{phone}}', maskPhonePlain(hrefInfo.phone).replace("+", "")).replace('{{text}}', '')
            } else if (whatsappLinkSettings.textType === 'activationScript' && textMessage) {
                link = template
                    .replace('{{phone}}', maskPhonePlain(hrefInfo.phone).replace("+", ""))
                    .replace('{{text}}', encodeURIComponent(textMessage))
            } else {
                link = template
                    .replace('{{phone}}', maskPhonePlain(hrefInfo.phone).replace("+", ""))
                    .replace('{{text}}', encodeURIComponent(hrefInfo.text))
            }
            //Для обратной совместимости с gcb1
        } else {
            if (hrefInfo.link_template) {
                link = hrefInfo.link_template
                    .replace('{{phone}}', maskPhonePlain(hrefInfo.phone))
                    .replace('{{text}}', encodeURIComponent(hrefInfo.text))
            } else {
                link = `https://wa.me/${maskPhonePlain(hrefInfo.phone)}?text=${encodeURIComponent(
                    hrefInfo.text
                )}`
            }
        }
    }
    console.log({ link })
    return link
}

export function dateFilter(el: any, startDate: any, endDate: any) {
    if (
        moment(el.date).isSameOrAfter(moment(startDate), 'day') &&
        moment(el.date).isSameOrBefore(moment(endDate), 'day')
    ) {
        return true
    } else {
        return false
    }
}
export function toPercent(value: any) {
    return Math.round(value * 100) + '%'
}
